import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"

import Metadata from "../components/Metadata"
import FooterPitch from "../components/FooterPitch"

/* For Markdown Rendering */
const shortcodes = {}

export const pageQuery = graphql`
  query ($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      excerpt(pruneLength: 160)
      frontmatter {
        slug
        title
      }
    }
  }
`

const Agreement = ({
  data: {
    mdx: { frontmatter, excerpt, body },
  },
  pageContext: { agreements },
}) => {
  return (
    <>
      <Metadata
        title={frontmatter.title + " – Programme"}
        description={excerpt}
        url={`https://programme.app/${frontmatter.slug}`}
      />
      {/* <NavBar theme="dark" /> */}
      <Container>
        <Sidebar>
          <ul>
            {agreements.map(page => (
              <li>
                <Link to={"/" + page.slug}>{page.title}</Link>
              </li>
            ))}
          </ul>
        </Sidebar>
        <Content>
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{body}</MDXRenderer>
          </MDXProvider>
        </Content>
        <Sidebar></Sidebar>
      </Container>
      <FooterPitch />
    </>
  )
}

const Container = styled.div`
  padding: 0 2em;
  display: flex;
  flex-direction: row;
  h1,
  h2,
  h3 {
    color: var(--white) !important;
  }

  p,
  li,
  a {
    color: var(--light-grey);
  }

  @media (max-width: 960px) {
    padding: 0 20px;
    flex-direction: column;
  }
`

const Content = styled.div`
  max-width: 640px;
  margin: 7em auto;
`

const Sidebar = styled.div`
  margin-top: 8em;
  margin-bottom: 8em;
  width: 260px;

  ul {
    padding: 0;
    position: sticky;
    top: 2em;
  }

  li {
    list-style: none;
  }

  @media (max-width: 960px) {
    margin: 20px auto 0;
    max-width: 640px;
    width: 100%;
  }
`

export default Agreement
